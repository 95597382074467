.cases {
    overflow: hidden;
    &.section {
        @include media(480px) {
            padding-bottom: 50px;
        }
    }
    .tag {
        display: flex;
        align-items: center;
        padding: vw(15px);
        font-size: vw(18px);
        margin-right: vw(20px);
        margin-bottom: vw(10px);
        &.no_tag {
            background-color: #e05940;
            color: #ffffff;
            line-height: 1;
            padding: vw(15px);
            cursor: pointer;
        }
        &:last-child {
            margin-right: 0;
        }
        @include smallDesc() {
            padding: vw(10px);
            font-size: vw(14px);
        }
        @include media(1024px) {
            padding: 10px;
            margin-right: 10px;
            margin-bottom: 10px;
            font-size: 14px;
        }
        &.active,
        &:hover {
            
            .cases-svg {
                fill: #ffffff;
            }
        }
    }
    &-title {
        display: block;
        margin-bottom: vw(20px);
        font-weight: 500;
        font-size: vw(28px);
        line-height: 120%;
        @include media(1440px) {
            font-size: vw(26px, 1440);
            margin-bottom: vw(20px, 1440);
        }
        @include media(1280px) {
            margin-bottom: vw(20px, 1280);
            font-size: vw(22px, 1280);
            line-height: 140%;
        }
        @include media(1024px) {
            margin-bottom: 12px;
            font-size: 20px;
        }
        @include media(480px) {
            font-size: 18px;
        }
    } 
    &-image {
        display: block;
        margin-bottom: vw(30px);
        overflow: hidden;
        border-radius: 5px;
        @include media(1280px) {
            margin-bottom: vw(25px, 1280);
        }
        @include media(1024px) {
            margin-bottom: 18px;
        }
    }
    .tns-outer {
        @include media(480px) {
            position: relative;
        }
        z-index: 5;
    }
    .tns-controls {
        position: absolute;
        top: 0;
        right: 0;
        padding-right: vw(170px);
        padding-top: vw(187px);
        z-index: 5;
        @include smallDesc() {
            padding-right: vw(100px);
        }
        @include media(1024px) {
            padding-top: 112px;
        }
        @include media(480px) {
            position: relative;
            padding: 25px 15px;
        }
        &:before {
            content: 'Другие кейсы';
            display: inline-block;
            padding-right: vw(20px);
            vertical-align: middle;
            @include media(1440px) {
                display: none;
            }
            @include media(1024px) {
                padding-right: 20px;
            }
            @include media(480px) {
                display: none;
            }
        }
    }
    &-svg {
        width: vw(16px);
        height: vw(16px);
        margin-right: vw(12px);
        color: #959C9C;
        transition-duration: 0.4s;

        @include media(1024px) {
            width: 14px;
            height: 14px;
            margin-right: 10px;
        }
    }
    &-other {
        margin-left: auto;
        margin-right: vw(30px);
        line-height: vw(50px);
        @include media(1024px) {
            margin-right: 150px;
        }
    }
    &-item {
        position: relative;
        padding-bottom: vw(80px);
        margin-bottom: vw(60px);
        border-bottom: 1px solid #B1B7B7;
        @include smallDesc() {
            padding-bottom: vw(65px);
            margin-bottom: vw(65px);
        }
        @include media(1024px) {
            padding-bottom: 55px;
            margin-bottom: 55px;
        }
        &.listitem {
            animation: fadeInUp 1s forwards;
        }
    }
    &-links {
        .link-ico {
            margin-bottom: vw(32px);
            @include media(1024px) {
                margin-bottom: 25px;
            }
        }
    }
    &-content {
        font-size: vw(16px);
        margin-bottom: vw(40px);
        padding-right: vw(30px);
        @include smallDesc() {
            font-size: vw(14px);
        }
        @include media(1024px) {
            font-size: 14px;
            padding-right: 10px;
            margin-bottom: 15px;
        }
        strong {
            color: #6E7373;
        }
    }
    &-aside {
        box-sizing: border-box;
        display: inline-block;
        vertical-align: top;
        letter-spacing: normal;
        @include media(480px) {
            order: 1;
            width: 100%;
            margin-bottom: 30px;
        }
    }
    &-graph {
        box-sizing: border-box;
        display: inline-block;
        vertical-align: bottom;
        letter-spacing: normal;
        text-align: center;
        min-height: vw(530px);
        @include media(1024px) {
            vertical-align: top;
        }
        @include media(480px) {
            order: 2;
            width: 100%;
        }
        .button {
            display: none;
            margin-top: 45px;
            @include media(1024px) {
                display: inline-block;
                margin-top: 45px;
            }
            @include media(480px) {
                display: none;
            }
        }
        img {
            width: 100%;
        }
    }
    .link-ico {
        font-size: vw(16px);
        margin-right: vw(65px);
        margin-bottom: 0;
        &.filled {
            background-color: #d4523a;
        }
        @include smallDesc() {
            font-size: vw(16px);
            margin-right: vw(65px);
        }
        @include media(1024px) {
            font-size: 14px;
            margin-right: 30px;
        }
        @include media(480px) {
            margin-bottom: 10px;
            &:last-child {
                margin-bottom: 0;
            }
            &.img {
                &::before {
                    width: 35px;
                    height: 35px;
                }
            }
        }
        &:last-child {
            margin-right: 0;
        }
    }
    &-button {
        &.third {
            display: none;
            @include media(480px) {
                display: block;
                position: relative;
                z-index: 5;
                background-color: $red;
                margin-top: 25px;
                &:hover {
                    background-color: $redSec;
                }
            }
        }
    }
    .half {
        .button {
            @include media(1024px) {
                display: none;
            }
        }
    }
    .inner-wrapper {
        .button {
            @include media(1024px) {
                display: none;
            }
        }
    }
    &-idx {
        span {
            display: inline-block;
            position: absolute;
            bottom: 0;
            left: 50%;
            width: vw(30px);
            height: vw(30px);
            line-height: vw(30px);
            background-color: #C4C4C4;
            border-radius: 50%;
            text-align: center;
            color: #ffffff;
            transform: translateX(-50%) translateY(50%);
            @include smallDesc() {
                width: vw(30px);
                height: vw(30px);
                line-height: vw(30px);
            }
            @include media(1024px) {
                width: 30px;
                height: 30px;
                line-height: 30px;
            }
        }
    }

    &-table {
        width: 100%;
        border-collapse: collapse;
        border: 1px solid #EEEEEE;
        margin-bottom: vw(35px);

        @include media(1024px) {
            margin-bottom: 30px;
        }

        &-td {

            &--first {
                text-align: left;
                width: 70%;
            }

            &--second {
                border-left: 1px solid #EEEEEE;
                width: 30%;
            }
        }

        thead {

            tr {
                border-bottom: 2px solid #ECAFF6;
                text-align: left;

                th {
                    padding: vw(25px) vw(30px);
                    text-transform: uppercase;
                    color: #000;

                    @include media(1024px) {
                        padding: 15px;
                    }

                    @include media(480px) {
                        padding: 10px 15px;
                    }
                }
            }
        }

        tbody {

            td {
                padding: vw(25px) vw(30px);
                color: #000;

                @include media(1024px) {
                    padding: 15px;
                }

                @include media(1024px) {
                    padding: 10px 15px;
                    font-size: 12px;
                }

                a {
                    color: #000;
                    border-bottom: 1px dashed #000;

                    &:hover {
                        text-decoration: none;
                    }
                }
            }
            
            tr {
                background-color: #FAFBFB;
                border-bottom: 1px solid #EEEEEE;

                &:nth-child(2n) {
                    background-color: #fff;
                }

                &:nth-child(4) ~ tr {
                    display: none;
                }
            }
        }
    }

    &-right-content {
        display: none;

        &.active {
            display: block;
        }

        &.visible {
            .cases-table {
                tbody {
                    tr:nth-child(4n) ~ tr {
                        display: table-row;
                    }
                }
            }
        }
    }

    .read_more {
        display: block;
        visibility: visible;
        opacity: 1;
        margin-left: 0;

        @include media(1024px) {
            margin-top: 0;
        }

        > span {
            &::before {
                content: attr(data-default);
            }
        }

        &.active {
            > span {
                &::before {
                    content: attr(data-active);
                }
            }
        }
    }
}