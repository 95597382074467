.cost {

  &_block {
    width: calc(100% / 3 - #{vw(47px)});
    margin-right: 0;

    @include media(1024px) {
      margin-right: 0;
      width: 100%;
    }

    &:nth-child(3n) {
      margin-right: 0;
    }

    &_head {
      position: relative;
      padding: vw(40px) vw(80px) vw(30px) vw(40px);
      color: #fff;
      overflow: hidden;
      z-index: -2;

      @include media(1024px) {
        padding: 30px 50px 20px 20px;
        min-height: 185px;
      }

      @include media(480px) {
        padding: 20px 40px 20px 20px;
      }

      &--black {
        background-color: black;
        
      }

      &--red {
        background-color: $red;
        .cost_block_tariff {
            color: #FBA79B;
        }
        .cost_block_title {
          border-color: #FBA79B;
        }
      }
    }
    &_item {
        border-radius: 20px;
        overflow: hidden;
    }
    &_img {
        width: 177px;
        position: absolute;
        bottom: 0;
        right: 0;
        object-fit: cover;
        z-index: -1;
        @include media(1024px) {
           
          }
    }
    &_title {
      padding-bottom: vw(35px);
      margin-bottom: vw(25px);
      border-bottom: 1px solid #323333;
      font-weight: 600;
      font-size: vw(18px);
      line-height: 150%;
      
      @include media(1024px) {
        font-size: 16px;
        padding-bottom: 24px;
        margin-bottom: 12px;
      }
    }

    &_total {
      font-size: vw(20px);
      line-height: 140%;

      @include media(1024px) {
        font-size: 16px;
      }
    }

    &_summ {
      font-size: vw(42px);
      font-weight: 800;
      line-height: 140%;

      @include media(1024px) {
        font-size: 26px;
      }
    }
    &_tariff {
        font-weight: 400;
        font-size: vh(14px);
        text-transform: uppercase;
        color: #6E7373;
        margin-bottom: 10px;
    }
    &_text {
    margin-bottom: 30px;
    }
    &_decor {
      position: absolute;
      top: vw(-27px);
      right: 0;
      transform: rotate(-90deg);
      transform-origin: right bottom;
      font-size: vw(35px);
      font-weight: 800;
      line-height: 80%;

      @include media(1024px) {
        top: -23px;
        font-size: 29px;
      }
    }

    &_body {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      position: relative;
      padding: vw(40px);
      font-size: vw(18px);
      border: 1px solid transparent;
      background: linear-gradient(#fff 0 0) padding-box, linear-gradient(#ffffff, #C6C6C6) border-box;
      border-radius: 20px;

      @include media(1024px) {
        padding: 30px;
        font-size: 14px;
      }

      &.content ul li:before {
        border-radius: 0;
      }
    }

    &_button {
      width: 100% !important;
      max-width: none;
      border-radius: 10px;

      @include smallDesc() {
        font-size: vw(14px);
      }

      @include media(1024px) {
        font-size: 14px;
      }
      &.transp-red {
          margin-bottom: 17px;
          color: #e05940 !important;
      }
    }

    .read_more {
      padding-top: vw(20px);

      &::before {
        content: attr(data-default);
      }

      &.active {
        &::before {
          content: attr(data-active);
        }
      }
    }

    .hide_content {
      
      li {

        &:nth-child(4) ~ li {
          display: none;
        }
      }

      &.visible {

        li {

          &:nth-child(4) ~ li {
            display: block;
          }
        }
      }
    }
  }
}