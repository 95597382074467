@function decimal-round ($number, $digits: 0, $mode: round) {
    $n: 1;
    // $number must be a number
    @if type-of($number) != number {
        @warn '#{ $number } is not a number.';
        @return $number;
    }
    // $digits must be a unitless number
    @if type-of($digits) != number {
        @warn '#{ $digits } is not a number.';
        @return $number;
    } @else if not unitless($digits) {
        @warn '#{ $digits } has a unit.';
        @return $number;
    }
    @for $i from 1 through $digits {
        $n: $n * 10;
    }
    @if $mode == round {
        @return round($number * $n) / $n;
    } @else if $mode == ceil {
        @return ceil($number * $n) / $n;
    } @else if $mode == floor {
        @return floor($number * $n) / $n;
    } @else {
        @warn '#{ $mode } is undefined keyword.';
        @return $number;
    }
}

$max-breakpoint: 1920 !default;

@function vw($target, $dop: '') {

    @if ($dop != '') {
        $vw-context: ($dop * .01) * 1px;
        @return ($target/$vw-context) * 1vw;
    } @else {
        $vw-context: ($max-breakpoint * .01) * 1px;
        @return  ($target/$vw-context) * 1vw;
    }
}


@mixin scrollbar($thumbcolor, $rangecolor, $width) {
    scrollbar-color: $thumbcolor $rangecolor;
    scrollbar-width: thin;

    &::-webkit-scrollbar {
        width: $width;
    }
    &::-webkit-scrollbar-track {
        background-color: $rangecolor;
    }
    &::-webkit-scrollbar-thumb {
        background-color: $thumbcolor;
    }
}

@mixin media($width) {
    @media screen and (max-width: $width) {
        @content;
    }
}

@mixin vwmedia($width) {
    @media screen and (max-width: '#{$width}px') {
        $max-breakpoint: $width !global;
        @content;
    }
    $max-breakpoint: 1920 !global;
}

@mixin smallDesc() {
    @include vwmedia(1440) {
        @content
    }
    @include vwmedia(1280) {
        @content
    }
   /*@media screen and (max-width: 1440px) {
        $max-breakpoint: 1440 !global;
        @content;
    }
    @media screen and (max-width: 1280px) {
        $max-breakpoint: 1280 !global;
        @content;
    }*/
    $max-breakpoint: 1920 !global;
}

@mixin mediamin($width) {
    @media screen and (min-width: $width) {
        @content;
    }
}

@mixin mediaheight($height) {
    @media screen and (max-height: $height) {
        @content;
    }
}

@mixin mediaDiff($width, $height) {
    @media screen and (max-width: $width) and (min-height: $height) {
        @content;
    }
}

@mixin mediaDiffs($width, $height) {
    @media screen and (min-width: $width) and (max-height: $height) {
        @content;
    }
}

@mixin inline-block($valign, $parent: item, $important: item) {
    @if ($parent == 'parent') {
        letter-spacing: -0.3em;
    } @else {
        letter-spacing: normal;
    }
    @if ($important == 'important') {
        display: inline-block !important;
    } @else {
        display: inline-block;
    }
    vertical-align: $valign;
    box-sizing: border-box;
}

@mixin flex($spaces, $align) {
    display: flex;
    @if ($spaces != '') {
        justify-content: $spaces;
    }
    @if ($align != '') {
        align-items: $align;
    }
}