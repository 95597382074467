.dynamic {

  &s {
    background-color: black;
  }

  &_item {
    position: relative;
    width: calc(50% - #{vw(15px)});
    margin-right: vw(30px);
    margin-bottom: vw(70px);

    @include media(1024px) {
      margin-right: 30px;
      width: calc(50% - 15px);
    }

    @include media(480px) {
      margin-right: 0;
      width: 100%;
    }

    &--left {
      position: relative;
      width: vw(285px);
      padding-left: vw(20px);
      margin-right: vw(30px);
      font-size: vw(20px);
      font-weight: 400;

      @include media(1440px) {
        padding-left: 0;
      }

      @include media(1024px) {
        font-size: 16px;
        margin-right: 10px;
        width: calc(100% - 185px);
      }

      @include media(480px) {
        width: calc(100% - 175px);
        font-size: 14px;
      }
    }

    &--right {
      padding: vw(40px);
      width: vw(365px);
      background-color: #151616;
      font-size: vw(18px);
      line-height: 150%;
      word-break: break-word;

      @include media(1024px) {
        width: 185px;
        padding: 20px;
        font-size: 14px;
      }

      @include media(480px) {
        width: 165px;
        padding: 15px;
      }
    }

    &:nth-child(2n) {
      margin-right: 0;

      .dynamic_item--right {
        position: relative;

        &::before {
          content: '';
          position: absolute;
          left: 100%;
          top: 50%;
          width: calc(100% + #{vw(180px)});
          height: 1px;
          background-image: linear-gradient(270deg, #E65A45 -12.24%, #0F1010 101.63%);

          @include media(480px) {
            display: none;
          }
        }
      }
    }

    &:nth-child(3n) {

      .dynamic_item--left {
        
        .dynamic_item_line {
          width: calc(100% + #{vw(180px)});
          margin-left: vw(-180px);
        }
      }      
    }

    &:last-child {

      .dynamic_item--right {

        &::before {
          display: none;
        }
      }
    }

    &_image {
      width: vw(50px);
      margin-bottom: vw(20px);

      @include media(1024px) {
        width: 32px;
        margin-bottom: 15px;
      }

      @include media(480px) {
        margin-bottom: 10px;
      }
    }

    &_subtitle {
      color: #fff;
    }

    &_title {
      order: 0;
    }

    &_line {
      position: relative;
      display: block;
      margin: vw(20px) 0;
      width: 100%;
      height: 1px;
      background-image: linear-gradient(270deg, #E65A45 -12.24%, #0F1010 101.63%);

      @include media(1024px) {
        margin: 20px 0;
      }

      &::after {
        content: '';
        position: absolute;
        right: 0;
        top: 50%;
        width: vw(8px);
        height: vw(8px);
        border-bottom: 1px solid $red;
        border-right: 1px solid $red;
        transform-origin: center;
        transform: translateY(-50%) rotate(-45deg);

        @include media(1024px) {
          width: 8px;
          height: 8px;
        }
      }
    }
  }
}