$bgGray: #0f1010;
$header: #030404;
$gray: #151616;
$lightGray: #FAFAFA;
$text: #B1B7B7;
$border: #8A8A8A;
$date: #6E7373;
$darkBorder: #6E7373;
$selected: #212121;
$darkGray: #292929;
$lightText: #F4F4F4;
$red: #e05940;
$redSec: #d4523a;
$bar: #282828;