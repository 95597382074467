@import '../settings/mixins';

.questions {
    padding-bottom: vw(100px);
    @include media(1024px) {
        padding-bottom: 100px;
    }
    @include media(480px) {
        padding-bottom: 60px;
    }
    &-button {
        width: 100%;
    }
    &-title {
        width: calc(100% - #{vw(90px)});
        margin-bottom: 0;
        text-align: left;
        line-height: normal;
        @include media(1280px) {
            font-size: vw(14px, 1280);
        }
        @include media(1024px) {
            width: calc(100% - 50px);
            font-size: 14px;
        }
        @include media(480px) {
            font-size: 16px;
        }
    }
    &-wrapp {
        padding-top: vw(30px);
    }
    &-elem {
        padding: vw(45px) 0;
        border-top: 1px solid #323333;
        &.last,
        &:last-child {
            border-bottom: 1px solid #323333;
        }
        @include media(1024px) {
            padding: 30px 0 30px;
        }
        .hidden-block {
            height: 0;
            overflow: hidden;
        }
        p:first-child {
            @include media(480px) {
                padding-top: 20px;
            }
        }
    }
    &-block {
        .rows {
            margin-bottom: vw(60px);
            @include media(1024px) {
                margin-bottom: 30px;
            }
        }
    }
}

.white {

    .questions {

        &-elem {
            border-color: #EEEEEE;
        }

        &-wrapp {
            
            ul {
                padding-left: vw(12px);

                li {

                    &::before {
                        border-radius: 0;
                    }
                }
            }
        }
    }

    .opened .button_round {
        border-color: #000;
    }
}
