$red: #E65A45;
$redSec: #d4523a;
$gray: #B1B7B7;
$gray2: #6E7373;
$grayLight: #FAFBFB;

$specialColor: #151616;

$desktop: 1280px;
$smallDesk: 1024px;
$tablet: 992px;
$phone: 480px;

.awards {
  &__info,
  &__block {
      &_item {
          &:nth-child(1) {
              width: 10%;
              @include media($tablet) {
                  width: 12.5%;
              }
              @include media($phone) {
                  width: 20%;
              }
          }
          &:nth-child(2) {
              width: 25%;
              @include media($tablet) {
                  width: 28%;
              }
              @include media($phone) {
                  width: 65%;
              }
          }
          &:nth-child(3) {
              width: 10%;
              padding: 0 3%;
              @include media($desktop) {
                  width: 17%;
              }
              @include media($tablet) {
                  width: 12.5%;
              }
              @include media($phone) {
                  width: 15%;
                  padding: 0;
              }
          }
          &:nth-child(4) {
              width: 25%;
              @include media($desktop) {
                  width: 28%;
              }
              @include media($tablet) {
                  width: 37%;
                  padding-left: 2.5%;
              }
              @include media($phone) {
                  display: none;
              }
          }
          &:nth-child(5) {
              width: 30%;
              @include media($desktop) {
                  width: 20%;
              }
              @include media($tablet) {
                  width: 10%;
              }
              @include media($phone) {
                  display: none;
              }
          }
          
          small {
            @include media($phone) {
                font-size: 16px;
            }  
          }
      }
  }
  
  &__info {
      padding-top: vw(111px);
      margin-bottom: vw(30px);
      font-size: vw(16px);
      color: #B1B7B7;
      @include media($desktop) {
          font-size: vw(16px, 1280);
      }
      @include media($smallDesk) {
          font-size: 16px
      }
      @include media($tablet) {
          font-size: 14px;
      }
      @include media($phone) {
          font-size: 12px;
      }
  }
  
  &__block {
      border-top: 1px solid #323333;
      padding: vw(40px) 0;
      transition: 0.8s;
      height: vw(119px);
      @include media($desktop) {
          height: vw(110px, 1280);
      }
      @include media($smallDesk) {
          height: 110px;
      }
      @include media($tablet) {
          height: 100px;
      }
      &:last-child {
         border-bottom: 1px solid #323333; 
      }
      
      &_item {
          position: relative;
          font-weight: 400;
          font-size: vw(17.28px);
          color: #fff;
          @include media($desktop) {
              font-size: vw(17.28px, 1280);
          }
          @include media($smallDesk) {
              font-size: 18px;
          }
          @include media($tablet) {
              font-size: 16px;
          }
          @include media($phone) {
              font-size: 14px;
          }
          &:nth-child(2) {
              &:before {
                  content: '';
                  display: inline-flex;
                  width: vw(21px);
                  height: vw(28px);
                  margin-right: vw(14px);
                  background: {
                      image: url('../img/about/version_icon.svg');
                      size: cover;
                  };
                  @include media($desktop) {
                      width: vw(18px, 1280);
                      height: vw(24px, 1280);
                  }
                  @include media($tablet) {
                      width: 14px;
                      height: 19px;
                  }
                  @include media($phone) {
                      width: 12px;
                      height: 16px;
                      margin-right: 8px;
                  }
              }
          }
          &:nth-child(3) {
              small {
                  width: vw(46px);
                  height: vw(38px);
                  text-align: center;
                  background: {
                      image: url('../img/about/rating-bg.png');
                      size: contain;
                  };
                  @include media($desktop) {
                      width: vw(46px, 1280);
                      height: vw(38px, 1280);
                      background-repeat: no-repeat;
                  }
                  @include media($smallDesk) {
                      width: 46px;
                      height: 38px;
                  }
                  @include media($tablet) {
                      width: 46px;
                      height: 38px;
                  }
              }
          }
          &:nth-child(5) {
              &:before {
                  content: '';
                  display: none;
                  width: 44px;
                  height: 44px;
                  border: 1px solid $red;
                  border-radius: 50%;
                  margin: auto;
                  margin-right: 0;
                  background: {
                      image: url('../img/about/icon-document.svg');
                      repeat: no-repeat;
                      size: 18px 18px;
                      position: center center;
                  };
                  @include media($tablet) {
                      display: block;
                  }
              }
              
              img {
                  z-index: 1;
                  height: vw(327px);
                  visibility: hidden;
                  opacity: 0;
                  transition: 0.8s;
                  position: absolute;
                  top: vw(-159px);
                  right: vw(23px);
                  @include media($desktop) {
                      top: vw(-115px, 1280);
                      height: vw(228px, 1280);
                      right: 0;
                  }
                  @include media($smallDesk) {
                      top: -115px;
                      height: 228px;
                  }
                  @include media($tablet) {
                      display: none;
                  }
              }
          }
          
          strong {
              font-weight: 400;
          }
          
          .rating {
              display: none;
              max-width: 90%;
              padding-left: 20px;
              padding-top: 10px;
              font-size: 12px;
              font-weight: normal;
              @include media($phone) {
                  width: 100%;
                  display: block;
              }
          }
      }
      
      
      &:hover {
          text-decoration: none;
          border-color: $red;
          transition: 0.8s;
          
          img {
              visibility: visible;
              opacity: 1;
              transition: 0.8s;
          }
          
          & + .awards__block {
              border-top-color: $red;
          }
      }
      
  }
}