.seo__form {
    background-color: #fff;
    
    .half-block {
        width: 60%;
    }
    
    .form-input {
        input {
            background-color: #fff;
        }
    }
    
    .h2 {
        margin-bottom: vw(75px);
        @include media($desktop) {
            margin-bottom: vw(63px, 1280);
        }
        @include media($smallDesk) {
            margin-bottom: 63px;
        }
    }

    &_title {
        width: vw(850px);

        @include media(1024px) {
            max-width: 500px;
            width: 100%;
        }
    }
    
    &_wrapp {
        background-color: #FAFBFB;
        padding: {
            top: vw(57px);
            bottom: 0;
        };
        margin-bottom: vw(65px);
        @include media($desktop) {
            padding-top: vw(40px, 1280);
        }
        @include media($smallDesk) {
            padding-top: 40px;
            margin-bottom: 25px;
        }
        @include media($tablet) {
            margin-bottom: 25px;
            padding-top: 30px;
        }
        @include media($phone) {
            margin-bottom: 20px;
        }
    }
    
    &_block {
        flex-wrap: wrap;
        
        &:nth-child(2) {
            padding-top: vw(50px);
        }
        
        .form-title {
            font-family: 'Montserrat', sans-serif;
            color: #000;
            font-weight: 500;
        }

        .vs__dropdown-toggle,
        .ss-selected-option {
            position: relative;
            background-color: #fff;
            border: 1px solid #EAF2F2;
            color: #6E7373;
            z-index: 10;
            
            &:after {
                display: none;
            }
            
            &:before {
                border-top-color: #939393;
            }
        }
        
        .ss-dropdown {
            z-index: 10;
            background-color: #fff;
        }
        
        .style-select, .style-select * {
            color: #6E7373;
        }
        
        .style-select .ss-dropdown .ss-option.highlighted {
            background-color: $red;
            color: #fff;
        }
        
        input {
            &:not(.vs__search){
                position: relative;
                border: 1px solid #EAF2F2;
                background-color: #fff;
                color: #6E7373;
                z-index: 1;

                &:focus,
                &:hover {
                    background-color: #fff;
                }

                &:placeholder {
                    color: #6E7373;
                }
            }
        }
        
        button {
            max-width: 100%;
            @include media($tablet) {
                height: 50px;
                padding: 13px 10px;
            }
        }

        .form-input {
            width: 31%;
            margin-right: 3.499%;
            margin-bottom: vw(65px);
            @include media($tablet) {
                width: 48%;
                margin-right: 4%;
            }
            @include media($phone) {
                width: 100%;
                margin-right: 0;
                margin-bottom: 15px;
            }
            &:nth-child(2n) {
                @include media($tablet) {
                    margin-right: 0;
                }
            }
            &:nth-child(3n) {
                margin-right: 0;
                @include media($tablet) {
                    margin-right: 4%;
                }
                @include media($phone) {
                    margin-right: 0;
                }
            }
        }

    }
    
    &_item {
        width: 100%;
        margin-bottom: vw(50px);
        align-items: flex-start;
        @include media($tablet) {
            width: 48%;
            margin-right: 4%;
        }
        @include media($phone) {
            width: 100%;
            margin-right: 0;
        }
        &:nth-child(3n) {
            margin-right: 0;
        }
        
        .form-input {
            width: 100%;
            @include media($desktop) {
                margin-bottom: 0;
            }
            @include media($phone){
                margin-bottom: 12px;     
            }
        }
        
        &.form_block_text {
            @include media($tablet) {
                width: 100%;
                margin-right: 0;
                margin-bottom: 20px;
            }
        }
        
        &.form_item_bottom {
            // padding-top: vw(36px);
            @include media($desktop) {
                padding-top: 0;
            }
            @include media($tablet) {
                margin-right: 0;
                padding-top: 32px;
            }
            @include media($phone) {
                padding-top: 0;
            }
        }
        
        &.form_item_text {
            align-self: center;
            width: vw(620px);
            margin: 0;
            padding: 0;
            font-size: vw(16px);
            line-height: 1.3;
            color: #6E7373;
            @include media($desktop) {
                width: 65.499%;
                margin-right: 0;
                font-size: vw(16px, 1280);
            }
            @include media($smallDesk) {
                font-size: 16px;
            }
            @include media($tablet) {
                font-size: 14px;
                width: 100%;
            }
            @include media($phone){
                padding-top: 15px;
                font-size: 12px;
            }
        }
    }
    
    &_text {
        color: #000;
        
        strong {
            position: relative;
            display: block;
            padding-left: vw(30px);
            font-size: vw(20px);
            line-height: 1.3;
            font-weight: 600;
            margin-bottom: vw(15px);
            @include media($desktop) {
                font-size: vw(18px, 1280);
                margin-bottom: vw(15px, 1280);
                padding-left: vw(35px, 1280);
            }
            @include media($smallDesk) {
                font-size: 18px;
                margin-bottom: 15px;
                padding-left: 35px;
            }
            @include media($tablet) {
                font-size: 16px;
                margin-bottom: 5px;
                padding-left: 31px;
            }
            @include media($phone) {
                font-size: 14px;
            }
            &:before {
                content: '';
                position: absolute;
                width: vw(22px);
                height: vw(22px);
                top: 50%;
                transform: translateY(-50%);
                left: 0;
                background: {
                    image: url('../img/about/form-icon.svg');
                    size: cover;
                    repeat: no-repeat;
                };
                @include media($desktop) {
                    width: vw(20px, 1280);
                    height: vw(19px, 1280);
                }
                @include media($smallDesk) {
                    width: 20px;
                    height: 19px;
                }
                @include media($tablet) {
                    width: 16px;
                    height: 15px;
                }
            }
        }
        
        span {
            font-size: vw(18px);
            font-weight: 400;
            @include media($desktop) {
                font-size: vw(16px, 1280);
            }
            @include media($smallDesk) {
                font-size: 16px;
            }
            @include media($tablet) {
                font-size: 14px;
            }
        }
    }
}

.form_item_bottom {
    margin-bottom: 0 !important;

    .form-title {
        display: none !important;
        @include media(810px) {
            display: block !important;
        }
        @include media(480px) {
            display: none !important;
        }
    }
}

.feedback-form{
    .seo__form_wrapp{
        position: relative;
        z-index: 10;
    }
    .seo__form_block_top{
        .form-input{
            &:nth-child(1),
            &:nth-child(3){
                //margin-right: 0;
            }
            &:nth-child(2){
                //margin-right: 4%;
            }
        }
        .seo__form_text{
            @include media(992px){
                width: 100%;
            }
        }
        .form-input-select{
            @include media(992px){
                margin-right: 4%;
            }
        }
        .form-input-select + .form-input{
            margin-right: 0;
        }
    }
    .ss-dropdown{
        background-color: #fff;
    }
    .vs__selected-options{
        overflow: auto;
        &::-webkit-scrollbar {
            display: none;
        }

        /* Hide scrollbar for IE, Edge and Firefox */
        & {
            -ms-overflow-style: none;  /* IE and Edge */
            scrollbar-width: none;  /* Firefox */
        }
    }
    .vs__search:hover,
    .vs__search:focus,
    .vs__search{
        background-color: transparent;
        position: absolute;
        left: 0;
        top: 0;
    }
    .vs__dropdown-toggle {
        height: vw(60px);
        line-height: vw(60px);
        border-radius: 10px;
        
        @include media(1440px) {
            height: vw(60px, 1440);
            line-height: vw(60px, 1440);
        }
        @include media(1280px) {
            height: vw(60px, 1280);
            line-height: vw(60px, 1280);
        }
        @include media(1024px) {
            height: 50px;
            line-height: 50px;
        }
    }
}