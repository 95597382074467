.strategy {

  &_block {
    // padding-top: vw(40px);
    border-top: 1px solid #323333;
  }

  &_half {
    width: calc(50% - #{vw(35px)});
    margin-right: vw(70px);

    &:nth-child(2n) {
      margin-right: 0;
    }

    @include smallDesc() {
      width: calc(50% - #{vw(15px)});
      margin-right: vw(30px);
    }

    @include media(1024px) {
      width: calc(50% - 15px);
      margin-right: 30px;
    }
  }

  &_button {
    position: relative;
    width: 100%;
    padding: vw(40px) vw(70px) vw(40px) 0;
    color: #fff;
    font-weight: 800;
    font-size: vw(28px);
    line-height: vw(34px);
    text-align: left;
    transition-duration: 0.4s;

    @include media(1024px) {
      padding: 30px 45px 30px 0;
      font-size: 20px;
      line-height: 24px;
    }

    @include media(480px) {
      padding-right: 0;
      padding-bottom: 0;

      &.active {
        padding-bottom: 30px;

        & + .strategy_content {
          display: block;
        }
      }
    }

    &::before {
      content: '';
      position: absolute;
      right: 0;
      top: 50%;
      width: vw(30px);
      height: 1px;
      background-image: linear-gradient(270deg, #E65A45 -12.24%, #0F1010 101.63%);
      transform: translateX(-10px);
      opacity: 0;
      transition-duration: 0.8s;

      @include media(1024px) {
        width: 30px;
      }

      @include media(480px) {
        display: none;
      }
    }

    &::after {
      content: '';
      position: absolute;
      right: 0;
      top: 50%;
      width: vw(8px);
      height: vw(8px);
      border-bottom: 1px solid $red;
      border-right: 1px solid $red;
      transform-origin: center;
      transform: translateY(-50%) translateX(-10px) rotate(-45deg);
      opacity: 0;
      transition-duration: 0.8s;

      @include media(1024px) {
        width: 8px;
        height: 8px;
      }

      @include media(480px) {
        display: none;
      }
    }

    &.active {
      color: $red;

      &::before,
      &::after {
        opacity: 1;
      }

      &::before {
        transform: translateX(0);
      }

      &::after {
        transform: translateY(-50%) translateX(0) rotate(-45deg);
      }
    }
  }

  &_item {
    border-bottom: 1px solid #323333;

    @include media(480px) {
      padding-bottom: 30px;
    }
    
    & > .f_wrap .flex  {
        @include media(480px) {
            flex-wrap: nowrap;
        }
    }
    
    & .h4.title {
        line-height: 150%;
    }

    &:last-child {
      border-bottom: none;
    }

    .strategy_content {
      display: none;
    }
  }

  &--left {
    @include media(480px) {
      width: 100%;
      margin-right: 0;
    }
  }

  &--right {
    padding-top: vw(40px);

    @include media(480px) {
      display: none;
    }
  }

  &_content {
    p, ul {
      font-size: vw(18px);
      margin-bottom: vw(40px);
      
      &:last-child {
          margin-bottom: 0;
      }

      @include smallDesc() {
        font-size: vw(16px);
      }

      @include media(1024px) {
        font-size: 14px;
        margin-bottom: 30px;
      }
    }
    
    ul {
        li {
            position: relative;
            padding-left: vw(20px);
            @include media(1024px) {
                padding-left: 20px;
            }

            
        }
        &>li:before {
            content: '';
            display: block;
            position: absolute;
            left: 0;
            top: vw(10px);
            width: vw(5px);
            height: vw(5px);
            background-color: $red;
            border-radius: 50%;
            @include media(1024px) {
                top: 10px;
                width: 5px;
                height: 5px;
            }
        }
    }
  }
}