.on-right .circle__inner, .circle__mask.on-left {
    clip: rect(0, 10px, 20px, 0);
}

.on-left .circle__inner, .circle__mask.on-right {
    clip: rect(0, 20px, 20px, 10px);
}

.circle-btn {
    position: relative;
    display: inline-block;
    margin-right: 20px;
    vertical-align: middle;
    background: rgba(255, 255, 255, 0.6);
    cursor: pointer;

    .circle__inner, .circle__mask {
        width: 20px;
        height: 20px;
        border-radius: 50%;
    }

    &.tns-nav-active{
        width: 20px;
        height: 20px;
        border-radius: 50%;

        .on-left .circle__inner {
            animation: circle-left 20s linear 0s;
        }
        .on-right .circle__inner {
            animation: circle-right 20s linear 0s;
        }
    }
    &:not(.tns-nav-active){
        display: inline-block;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        margin-right: 20px;
        border: 2px solid rgba(255,255,255,.2);
        background: rgba(0,0,0,.2);

        span{
            display: none;
        }
    }
    @include media(768px){
        &.tns-nav-active{
            width: 16px;
            height: 16px;
        }
        &:not(.tns-nav-active){
            display: inline-block;
            width: 8px;
            height: 8px;
        }
        .circle__inner, .circle__mask {
            width: 16px;
            height: 16px;
        }
        .on-right .circle__inner, .circle__mask.on-left {
            clip: rect(0, 8px, 16px, 0);
        }

        .on-left .circle__inner, .circle__mask.on-right {
            clip: rect(0, 16px, 16px, 8px);
        }
    }
}

.circle__mask {
    position: absolute;
    left: 0;
    top: 0;
}
.circle__inner {
    position: absolute;
    width: 100%;
    height: 100%;
    background: #000;
}

.circle__holder {
    position: absolute;
    width: 80%;
    height: 80%;
    left: 10%;
    top: 10%;
    border-radius: 50%;
    background: #000;
}

@keyframes circle-left {
    0% {
        transform: rotate(0);
    }
    25% {
        transform: rotate(0);
    }
    50% {
        transform: rotate(180deg);
    }
    75% {
        transform: rotate(180deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
@keyframes circle-right {
    0% {
        transform: rotate(0);
    }
    25% {
        transform: rotate(180deg);
    }
    50% {
        transform: rotate(180deg);
    }
    75% {
        transform: rotate(360deg);
    }
    100% {
        transform: rotate(360deg);
    }
}