@import '../settings/mixins';
@import '../settings/colors';

.questions_second {
  padding-bottom: vw(100px);
  @include media(1024px) {
    padding-bottom: 100px;
  }
  @include media(480px) {
    padding-bottom: 60px;
  }
  &-button {
    width: 100%;
  }
  &-title {
    width: calc(100% - #{vw(90px)});
    margin-bottom: 0;
    text-align: left;
    line-height: normal;
    transition-duration: 0.4s;
    @include media(1280px) {
      font-size: vw(16px, 1280);
    }
    @include media(1024px) {
      width: calc(100% - 50px);
      font-size: 18px;
    }
    @include media(480px) {
      width: calc(100% - 70px);
      font-size: 14px;
      margin-right: auto;
    }
  }
  &-wrapp {
      padding-top: vw(20px);
      width: 100%;
      padding-left: vw(40px);

      @include media(1024px) {
        padding-left: 35px;
      }

      @include media(480px) {
        padding-left: 0;
      }
  }
  &-elem {
    padding: vw(25px) 0;
    border-top: 1px solid #EEEEEE;
    transition: border-color 0.4s;
    &.last,
    &:last-child {
      border-bottom: 1px solid #EEEEEE;
    }
    @include media(1024px) {
      padding: 20px 0 20px;
    }
    .hidden-block {
      height: 0;
      overflow: hidden;
    }
    .button_round {
      @include media(480px) {
        width: 30px;
        height: 30px;
      }
    }
    p:first-child {
      @include media(480px) {
        padding-top: 20px;
      }
    }
    .content ul li {
      padding-left: vw(30px);
      background: {
        size: vw(16px);
        position: left vw(2px);
        repeat: no-repeat;
        image: url('../img/svg/check_black.svg');
      }

      @include media(1024px) {
        padding-left: 30px;
        background-size: 16px;
      }

      &::before {
        display: none;
      }
    }

    &.active,
    &:hover {
      border-color: $red;

      & + .questions_second-elem {
        border-color: $red;
      }

      .questions_second-title {
        color: $red;
      }

      .questions_second-svg {
        fill: $red;
      }
    }
  }
  &-block {
      .rows {
          margin-bottom: vw(60px);
          @include media(1024px) {
              margin-bottom: 30px;
          }
      }
  }
  &-svg {
    width: vw(18px);
    height: vw(18px);
    margin-right: vw(20px);
    transition-duration: 0.4s;

    @include media(1024px) {
      min-width: 18px;
      min-height: 18px;
      margin-right: 20px;
    }

    @include media(1024px) {
      margin-right: 15px;
    }
  }
  &-rows {
    margin-bottom: vw(70px);
  }
}