@import 'settings/mixins';
@import 'modules/questions_second';
@import 'modules/questions';
@import 'modules/cases';
@import 'modules/dynamic';
@import 'modules/cost_block';
@import 'modules/awards';
@import 'modules/strategy';
@import 'modules/seo_form';
@import 'modules/slider';


$redcolor: #E65A45;

.main_page {
  position: relative;

  .text-right{
    text-align: right;
  }

  .special-main .circle_button{
    margin-top: 0 !important;

    @include media(810px) {
      width: 110px;
      height: 110px;
    }
  }

  .skills-item .icon {
    padding-left: 0;
  }

  .anchor-list{
    a{
      padding-left: 32px;
      position: relative;

      &::before{
        content: '';
        width: 12px;
        height: 12px;
        margin-right: 8px;
        display: inline-block;
        background-size: cover;
        background-repeat: no-repeat;
        background-image: url('/assets/templates/img/svg_icons/apps.svg');
        vertical-align: middle;
        position: absolute;
        top: 50%;
        left: 12px;
        transform: translateY(-50%);
      }

      &:nth-child(2){
        &::before{
          background-image: url('/assets/templates/img/svg_icons/briefcase.svg');
        }
      }
      &:nth-child(3){
        &::before{
          background-image: url('/assets/templates/img/svg_icons/diploma.svg');
        }
      }
    }

    @include mediamin(1280px){
      a{
        padding-left: 42px;

        &::before{
          width: 16px;
          height: 16px;
          margin-right: 12px;
          left: 15px;
        }
      }
    }

    @include media(480px) {
      display: none;
    }
  }

  .main-title{
    &.h1 {
      padding-right: 0;
      font-size: vw(77px);
      font-weight: 800;
      line-height: 130%;

      @include mediamin(768px){
        margin-bottom: vw(20px);
      }

      @include media(1280px) {
        font-size: vw(50px, 1280);
      }

      @include media(1024px) {
        font-size: 44px;
      }

      @include media(480px) {
        font-size: 24px !important;
      }
    }
  }

  @include media(1280px){
    .main-title{
      &.h1{
        font-size: vw(40px, 1280);
      }
    }
  }
  @include media(1024px){
    .main-title{
      &.h1{
        font-size: vw(44px, 1024);
      }
    }
  }
  @include media(768px){
    .main-title{
      &.h1{
        font-size: vw(44px,768)!important;
      }
    }
  }
  @include media(480px){
    .main-title{
      &.h1{
        font-size: 25px!important;
      }
    }
  }

  @include mediamin(1025px){
    &::before{
      content: '';
      width: vw(250px);
      height: 100%;
      display: block;
      background-color: #030404;
      position: fixed;
      left: 0;
      z-index: 7;
    }
  }
  @include smallDesc() {
    &::before{
      width: vw(222px);
    }
  }
}

.visually-hidden {
  position: absolute;
  clip: rect(0 0 0 0);
  width: 1px;
  height: 1px;
  margin: -1px;
}

.main__video_block{
  position: relative;
  width: 100%;
  height: 100%;

  .js-play-video{
    width: 100vw;
    height: 100%;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);

    @include media(1024px){
      left: 0;
      transform: none;
    }
  }

  iframe{
    height: 100% !important;
    width: 100%;

  }

  video{
    transform: translateX(-50%);
    position: absolute;
    left: 50%;
    top: 0;
    width: auto;
    height: 100%;

    @include media(1440px){
      width: 100%;
      object-fit: cover;
    }
  }
}

.grad{
  &.grad-red{
    @supports (-webkit-background-clip: text) {
      background: #fff;
      background: linear-gradient(172deg, rgba(230,90,69,1) 0%, rgba(255,255,255,1) 34%, rgba(255,255,255,0.48783263305322133) 100%);
      -webkit-text-fill-color: transparent;
      -webkit-background-clip: text;
    }
  }
}


.special.digital{
  .main-title {
    margin-bottom: 0;
  }

  .h1 {
    padding-right: 0;
    font-size: vw(68px);
    font-weight: 400;
    line-height: 130%;

    @include media(1280px) {
      font-size: vw(50px, 1280);
    }

    @include media(1024px) {
      font-size: 44px;
    }

    @include media(480px) {
      font-size: 30px !important;
    }
  }

  .special-main{
    margin-left: 0;

    @include media(1024px) {
      margin-right: 0;
    }
  }

  .get_present {
    padding-top: 50px;

    .form-input {
      margin-bottom: .4333vw;
    }

    .form-check {
      padding-top: .5625vw;
      width: 100%;
    }

    .gift_input {
      width: calc((100% - 40px) / 3);
      box-sizing: border-box;
      margin-bottom: 0;

      @include media(768px){
        width: 100%;
      }
    }

    .button{
      display: block;
      height: vw(60px);
      line-height: 1;

      @media (min-width: 1024px) and (max-width: 1280px){
        padding: 0;
        white-space: normal;
      }

      @include smallDesc() {
        height: vw(60px);
      }
      @include media(1024px) {
        height: 50px;
        padding-left: 15px;
        font-size: 14px;
      }

      @include media(768px){
        height: 55px;
        margin-top: 25px;
        margin-bottom: 25px;
      }

      span{
        padding: 0;
      }
    }
  }

  .phone_field{
    @media (min-width: 1024px) and (max-width: 1280px){
      padding-left: 10px;
      
      &__flag{
        display: none;
      }
      .js-dropdown__arrow{
        padding-left: 0;
      }
    }
  }

  .flex-gift {
    display: flex;
    flex-wrap: wrap;
    align-items: inherit;
  }

  .form-wrapper-gift {
    align-items: flex-start;
    justify-content: space-between;
    padding-top: 0;
  }

  .vantages {
    .vantages-row {
      padding-bottom: 1vw;
      padding-top: 1vw;
    }
  }

}


.promotion{
  .page-description{
    p{
      @include mediamin(1280px){
        font-weight: normal;
        font-size: vw(26px);
        line-height: vw(39px);
      }
    }
  }

  .special-desc-links{
    position: relative;

    @include mediamin(1440px){
      margin-bottom: vw(40px);
    }

    .circle_button{

      @include mediamin(768px){
        position: absolute;
        right: 0;
      }
    }
  }

  .special-banner{
    @include media(1024px){
      display: none;
    }
  }
}

.aboutVantages {
  &_title {
    max-width: 90%;
    font-weight: 500;
    font-size: vw(30px);
    padding-bottom: vw(95px);
    line-height: 1.3;
    background:  linear-gradient(101.35deg, #FFFFFF 23.9%, #B1B7B7 68.26%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    @include media($desktop) {
      font-size: vw(26px, 1280);
      max-width: 100%;
    }
    @include media($smallDesk) {
      font-size: 26px;
    }
    @include media($tablet) {
      font-size: 22px;
    }
    @include media($phone) {
      font-size: 18px;
      margin-bottom: 30px;
    }
  }

  &__item {
    width: 25%;
    margin-right: vw(90px);
    @include media($desktop) {
      width: 32%;
      margin-right: 1.999%;
    }
    @include media($tablet) {
      margin-right: 0;
    }

    &_img {
      max-width: vw(74px);
      margin-bottom: vw(30px);
      @include media($desktop) {
        max-width: vw(62px, 1280);
        margin-bottom: vw(25px);
      }
      @include media($smallDesk) {
        max-width: 62px;
        margin-bottom: 25px;
      }
      @include media($tablet) {
        max-width: 57px;
        margin-bottom: 25px;
      }
      @include media($phone) {
        max-width: 58px;
      }
    }

    &_title {
      font-weight: 600;
      font-size: vw(20px);
      line-height: 1.5;
      color: $red;
      margin-bottom: vw(11px);
      @include media($desktop) {
        font-size: vw(18px, 1280);
      }
      @include media($smallDesk) {
        font-size: 18px;
      }
      @include media($tablet) {
        font-size: 18px;
        margin-bottom: 10px;
      }
      @include media($phone) {
        font-size: 16px;
      }
    }

    &_description {
      font-size: vw(18px);
      line-height: 1.5;
      color: $gray;
      @include media($desktop) {
        font-size: vw(16px, 1280);
      }
      @include media($smallDesk) {
        font-size: 16px;
      }
      @include media($tablet) {
        font-size: 16px;
      }
      @include media($phone) {
        font-size: 14px;
      }
    }
  }

  .tns-controls button {
    &:before {
      background-color: $red;
    }

    &:after {
      border-color: $red;
    }
  }
}

#portfolio{
  .titles-block{
    margin-bottom: vw(80px);
  }
  #mse2_filters{
    display: block;
    visibility: visible;
    fieldset{
      border: none;
    }
    input{
      display: none;
    }
    .tag{
      cursor: pointer;
      margin-right: 10px;
    }
  }
  .category-selected{
    margin-top: 20px;

    &:empty{
      margin: 0;
    }
  }
  .blog-wrapper{
    padding-top: vw(20px);
  }
  .btn-more {
    color: #000;

    .svg_icon {
      fill: black;
    }
  }
  .pagination{
    display: none;
  }

  @include media(1440px){
    .titles-block{
      margin-bottom: 60px;
    }
  }
  @include media(1280px){
    .titles-block{
      margin-bottom: 50px;
    }
  }
  @include media(768px){
    .titles-block{
      margin-bottom: 40px;
    }
  }
}

.js-mse2-filter-more{
  .filter-item{
    opacity: 0;

    &.show{
      opacity: 1;
    }
  }

  .more{
    color: #959C9C;
    font-size: 14px;
    font-weight: normal;
    transition: .4s;
    cursor: pointer;

    &:hover{
      color: $redcolor;
    }
  }
}

.skills {

  &-item {
    padding: vw(35px) vw(45px) vw(50px) vw(45px);
    display: flex;
    justify-content: space-between;
    border-radius: 20px;

    @include media(1024px) {
      padding: 25px 30px;
    }

    @include media(480px) {
      padding: 20px;
    }

    &_icon {
      width: vw(64px);
      margin-bottom: vw(20px);

      @include media(1024px) {
        width: 45px;
        margin-bottom: 20px;
      }

      @include media(480px) {
        margin-bottom: 15px;
      }
    }
    .bottom{
      width: 100%;
      margin-top: vw(30px);

      @include media(1440px){
        margin-top: vw(18px);
      }
      @include media(1280px){
        margin-top: 15px;
      }
      @include media(768px){
        margin-top: 20px;
      }
    }
  }

  &-block {
    min-height: auto;
  }

  .tns-controls{
    text-align: left;

    @include media(480px){
      padding-top: 40px;
    }
  }

  .item{
    position: relative;
  }
  .bottom-text{
    position: absolute;
    left: vw(45px);
    top: calc(100% + 20px);
    font-size: vw(18px);
    line-height: vw(22px);

    @include media(1024px){
      font-size: vw(16px, 1024);
      line-height: 20px;
      left: 30px;
    }
    @include media(768px){
      font-size: 14px;
      line-height: 5px;
    }
    @include media(480px) {
      left: 20px;
    }
  }
  .tns-ovh{
    overflow: visible!important;
  }
}

.steps{
  overflow: visible;

  .strategy_content{
    .stage{
      font-weight: normal;
      font-size: 16px;
      line-height: 35px;
      text-transform: uppercase;
      color: #6E7373;
      margin-bottom: vw(50px);
    }
    h3{
      font-weight: bold;
      font-size: 18px;
      line-height: 22px;
      color: #FFFFFF;
    }

    ul{
      margin-bottom: vw(10px);
      li{
        padding-left: 15px;
        margin-bottom: vw(12px);

        &::before{
          content: '';
          position: absolute;
          left: 0;
          top: 10px;
          display: inline-block;
          width: 6px;
          height: 6px;
          background: #6E7373;
          vertical-align: middle;
        }
      }
    }

    svg{
      fill: #6E7373;
      vertical-align: middle;
      margin-right: 12px;
    }
  }

  &_item{
    .number{
      font-weight: 400;
    }
  }

  @include media(1440px){
    .strategy_content{
      .stage{
        font-size: 14px;
        line-height: 17px;
      }
      ul{
        li{
          margin-bottom: 10px;
        }
      }
      h3{
        font-size: 16px;
        line-height: 20px;
      }
    }
  }
  @include media(1280px){
    .strategy_content{
      .stage{
        margin-top: 16px;
        margin-bottom: 40px;
      }
    }
  }
  @include media(1280px){
    .strategy_content{
      ul{
        margin-bottom: 5px;
      }
    }
  }
}

#vantages{
  color: #000;

  .titles-block{
    .half-block{
      .right{
        color: #151616;
      }
    }
  }
  .small-title{
    color: #6E7373;
    font-weight: 400;
  }

  .vantages-list {
    border-bottom: none;

    &_title {
      font-size: vw(20px);
      color: $redcolor;

      @include smallDesc() {
        font-size: vw(16px);
      }
      @include media(1024px) {
        font-size: 18px;
      }

    }

    &_item {
      padding-left: 0;

      .left-block {
        width: vw(75px);

        @include media(1280px) {
          width: vw(64px, 1280);
        }

        svg,
        img {
          width: vw(45px);

          @include media(1280px) {
            width: vw(45px, 1280);
          }

          @include media(1024px) {
            width: 45px;
            margin-bottom: 25px;
          }
        }
      }

      .right-block {
        width: calc(100% - #{vw(75px)});
        padding-right: vw(70px);

        @include media(1280px) {
          width: calc(100% - #{vw(65px, 1280)});
          padding-right: vw(30px, 1280);
          padding-left: vw(25px, 1280);
        }

        @include media(1024px) {
          width: 100%;
          padding-right: 25px;
          padding-left: 0;
        }

        p {
          font-size: vw(18px);
          color: #000;

          @include smallDesc() {
            font-size: vw(14px);
          }

          @include media(1024px) {
            font-size: 16px;
          }
        }
      }
    }
  }

  @include mediamin(1280px){
    .titles-block{
      .half-block{
        &.right{
          p{
            padding-top: vw(15px);
          }
        }
      }
    }
  }
}

.about__video {
  background-color: #fff;
  padding-top: vw(109px);
  @include media($desktop) {
    padding-top: vw(93px, 1280);
  }
  @include media($smallDesk) {
    padding-top: 93px;
  }
  @include media(480px) {
    padding-top: 58px;
  }

  .arms-block {
    &:before,
    &:after {
      display: none;
    }

    @include media(480px) {
      display: none;
    }
  }

  .circle_button {
    &.t_big{
      @include media($smallDesk) {
        width: 110px;
        height: 110px;
        margin: 0;
        margin: auto;
        margin-right: 0;
      }
    }
    .svg_icon{
      fill:black;
    }

    &:hover{
      .svg_icon{
        fill: $redcolor;
      }
    }

  }

  .category-button_wrapper {
    @include media($desktop) {
      padding: 0 vw(100px);
    }
    @include media($smallDesk) {
      padding: 0 30px;
      margin-left: 0;
      width: 100%;
      height: auto;
    }
  }

  &_title {
    margin-bottom: vw(90px);
    @include media($desktop) {
      margin-bottom: vw(41px, 1280);
    }
    @include media($smallDesk) {
      margin-bottom: 41px;
    }
    @include media($tablet) {
      margin-bottom: 25px;
    }
    @include media($phone) {
      min-height: 80px;
      margin-bottom: 0;
    }
  }

  &_block {
    position: relative;
    width: 100%;
    border-radius: 10px;
    overflow: hidden;
    @include media(480px) {
      overflow: auto;
    }

    video {
      width: 100%;
      height: 100%;
      border-radius: 10px;
      overflow: hidden;
      @include media(480px) {
          overflow: auto;
        }

      &:focus,
      &:active{
        outline: none;
      }

      source {
        width: 100%;
        height: 100%;
      }
    }
  }

  &_poster {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    background: {
      repeat: no-repeat;
      size: cover;
    };
    @include media($phone) {
      background-position: center center;
    }
  }

  &_info {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    padding: vw(50px) vw(60px);
    transition: 0.8s;
    @include media($desktop) {
      padding: vw(40px, 1280) vw(35px, 1280);
    }
    @include media($smallDesk) {
      padding: 40px 35px;
    }
    @include media($tablet) {
      padding: 40px;
    }
    @include media($phone) {
      padding: 25px;
    }

    .video__description {
      position: relative;
      z-index: 2;
      color: #fff;

      &_title {
        font-size: vw(22px);
        line-height: 1.5;
        font-weight: 700;
        @include media($desktop) {
          font-size: vw(22px, 1280);
        }
        @include media($smallDesk) {
          font-size: 22px;
        }
        @include media($tablet) {
          font-size: 16px;
          margin-bottom: 5px;
        }
      }

      &_time {
        font-size: vw(18px);
        line-height: 1.5;
        @include media($desktop) {
          font-size: vw(16px, 1280);
        }
        @include media($smallDesk) {
          font-size: 16px;
        }
        @include media($tablet) {
          font-size: 14px;
        }

        .video_minutes,
        .video_seconds {
          display: inline-flex;

          span {
            margin-right: vw(2px);
          }
        }
      }
    }

    .play_video {
      position: absolute;
      width: vw(130px);
      height: vw(130px);
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: vw(16px);
      font-weight: 500;
      background-color: #fff;
      border-radius: 50%;
       // правки от 29,06,22 (Олег) z-index: 2;
      z-index: 999999; // правки от 29,06,22 (Олег)
      @include media($desktop) {
        width: vw(130px, 1280);
        height: vw(130px, 1280);
        font-size: vw(16px, 1280);
      }
      @include media($smallDesk) {
        width: 130px;
        height: 130px;
        font-size: 16px;
      }
      @include media($tablet) {
        width: 110px;
        height: 110px;
        font-size: 14px;
      }
      @include media($phone) {
        width: 96px;
        height: 96px;
        font-size: 12px;
      }
    }
  }

  .videoWrap-none {
    .about__video_info {
      display: none;
      transition: 0.8s;
    }
  }
}

//.blog{
//  .titles-block{
//    margin-bottom: vw(30px);
//  }
//
//  .all-posts{
//    margin-bottom: vw(70px);
//    display: inline-block;
//    font-weight: 600;
//    font-size: vw(16px);
//    line-height: vw(25px);
//    font-variant: small-caps;
//    color: $redcolor;
//
//    svg{
//      display: inline-block;
//      width: 15px;
//      height: 7px;
//      fill: $redcolor;
//      vertical-align: middle;
//      margin-right: vw(10px);
//    }
//  }
//
//  @include media(1440px){
//    .titles-block{
//      margin-bottom: vw(30px);
//    }
//  }
//  @include media(1280px){
//    .all-posts{
//      margin-bottom: 65px;
//      font-size: 16px;
//      line-height: 25px;
//    }
//  }
//  @include media(768px){
//    .titles-block{
//      margin-bottom: 15px;
//    }
//    .all-posts{
//      margin-bottom: 40px;
//    }
//  }
//}

.stroke_title {
  font-weight: 800;
  font-size: vw(86px);
  line-height: 140%;
  letter-spacing: 0.04em;
  text-transform: uppercase;

  @include media(1024px) {
    font-size: 51px;
  }

  @include media(480px) {
    font-size: 26px;
  }
}

.qa-block {
  
  .rows {
    margin-bottom: vw(70px);

    @include media(1024px) {
      margin-bottom: 40px;
    }
  }
}

.share {
  background-color: #0F1010;
  @include media($tablet) {
    padding-bottom: 68px;
  }
  @include media($phone) {
    padding-bottom: 58px;
  }

  &__list {
    padding-top: vw(80px);
    @include media($desktop) {
      padding-top: vw(62px, 1280);
    }
    @include media($smallDesk) {
      padding-top: 62px;
    }
    @include media($tablet) {
      padding-top: 41px;
    }
  }

  &__item {
    padding-bottom: vw(40px);
    margin-bottom: vw(40px);
    border-bottom: 1px solid #323333;
    @include media($desktop) {
      padding-bottom: vw(40px, 1280);
      margin-bottom: vw(40px, 1280);
    }
    @include media($smallDesk) {
      padding-bottom: 40px;
      margin-bottom: 40px;
    }
    @include media($phone) {
      padding-bottom: 19px;
      margin-bottom: 29px;
    }
    &:last-child {
      padding-bottom: 0;
      margin-bottom: 0;
      border: 0;
    }

    &_link {
      position: relative;
      font-size: vw(120px);
      font-weight: 800;
      line-height: normal;
      padding-right: vw(106px);
      text-transform: uppercase;
      color: #0F1010;
      transition: 0.8s;
      @include media($desktop) {
        font-size: vw(80px, 1280);
        padding-right: vw(79px, 1280);
      }
      @include media($smallDesk) {
        font-size: 80px;
        padding-right: 79px;
      }
      @include media($tablet) {
        font-size: 64px;
        padding-right: 59px;
      }
      @include media($phone) {
        font-size: 26px;
        padding-right: 29px;
        text-shadow:0px 1px 0 rgb(230,90,69),0px -1px 0 rgb(230,90,69),1px 0px 0 rgb(230,90,69),-1px 0px 0 rgb(230,90,69),1px 1px 0 rgb(230,90,69),1px -1px 0 rgb(230,90,69),-1px 1px 0 rgb(230,90,69),-1px -1px 0 rgb(230,90,69);
      }

      &:before {
        content: '';
        position: absolute;
        width: vw(46px);
        height: vw(46px);
        top: 50%;
        transform: translateY(-50%);
        right: 0;
        background: {
          image: url('../img/about/share-icon.svg');
          size: cover;
        };
        @include media($desktop) {
          width: vw(34px, 1280);
          height: vw(34px, 1280);
        }
        @include media($smallDesk) {
          width: 34px;
          height: 34px;
        }
        @include media($tablet) {
          width: 26px;
          height: 26px;
        }
        @include media($phone){
          width: 14px;
          height: 14px;
        }
      }

      &:hover {
        color: $red;
        transition: 0.8s;
        text-decoration: none;
      }
    }
  }
}






//.section-slider{
//
//  .slider-wrapper{
//    position: relative;
//
//    .tns-outer,
//    .tns-inner,
//    .slider-main{
//      height: 100%;
//    }
//    .tns-ovh{
//      height: 100%;
//      padding-top: 0;
//    }
//
//    @include mediamin(1440px){
//      height: 100%;
//    }
//  }
//
//  .slide-item{
//    padding: vw(100px) vw(110px) vw(60px);
//    width: 100%;
//    position: relative;
//    display: flex;
//    flex-direction: column;
//    justify-content: space-between;
//
//    .title{
//      font-weight: 800;
//      font-size: vw(120px);
//      line-height: 120%;
//      letter-spacing: 0.04em;
//      text-transform: uppercase;
//      color: #E65A45;
//    }
//
//    .bigText {
//      opacity: 0;
//
//      &.show{
//        opacity: 1;
//      }
//
//      .newTextItem{
//        opacity: 0;
//      }
//
//      .div_opacity{
//        animation: letter-glow 0.4s 0s ease both;
//      }
//    }
//    .desc-text{
//      opacity: 0;
//      transition: opacity 1.4s, transform 0.8s, letter-spacing 0.8s;
//
//      p{
//        font-weight: normal;
//        font-size: vw(22px);
//        line-height: 200%;
//        color: #FFFFFF;
//      }
//
//      &.active{
//        opacity: 1;
//      }
//    }
//
//    .bg{
//      position: absolute;
//      left: 0;
//      top: 0;
//      background-repeat: no-repeat;
//      background-position: center;
//      background-size: cover;
//      width: 100%;
//      height: 100%;
//      z-index: -2;
//
//      &::after{
//        content: '';
//        position: absolute;
//        left: 0;
//        top: 0;
//        width: 100%;
//        height: 100%;
//        background: linear-gradient(0deg, rgba(15, 16, 16, 0.85), rgba(15, 16, 16, 0.85));
//        z-index: -1;
//      }
//    }
//
//  }
//
//  .customize-tools{
//    position: absolute;
//    bottom: vw(60px);
//    left: vw(110px);
//  }
//
//  .slide-bottom{
//    display: flex;
//    justify-content: flex-end;
//  }
//  .slide-bottom-right{
//    vertical-align: middle;
//
//    font-weight: normal;
//    font-size: vw(16px);
//    line-height: 35px;
//    color: #FFF;
//
//    span{
//      vertical-align: middle;
//      display: inline-block;
//    }
//
//    i{
//      width: 80px;
//      height: 1px;
//      display: inline-block;
//      background: rgba(255, 255, 255, 0.2);
//      margin-left: 20px;
//      margin-right: 20px;
//      vertical-align: middle;
//    }
//  }
//
//  .circle_button{
//    position: absolute;
//    right: 10%;
//    top: 15%;
//    z-index: 10;
//
//    .plus{
//      background-color: $redcolor;
//    }
//  }
//
//  .tns-nav{
//    position: absolute;
//    bottom: 75px;
//    left: 110px;
//    z-index: 2;
//
//    button{
//      display: inline-block;
//      width: 10px;
//      height: 10px;
//      border-radius: 50%;
//      margin-right: 20px;
//      border: 2px solid rgba(255,255,255,.2);
//    }
//  }
//
//  @include media(1440px){
//
//    .circle_button {
//      position: absolute;
//      right: 5%;
//      top: 15%;
//      z-index: 10;
//    }
//
//    .customize-tools{
//      bottom: vw(50px);
//      left: 60px;
//    }
//  }
//
//  @include media(1370px){
//
//    .slide-item{
//      padding: vw(70px) 60px vw(50px);
//
//      .title{
//        font-size: vw(90px);
//        line-height: 120%;
//      }
//      .special-desc{
//        padding-bottom: 160px;
//      }
//      .desc-text{
//        p{
//          font-size: vw(18px);
//          line-height: 150%;
//        }
//      }
//    }
//    .customize-tools{
//      bottom: vw(50px);
//      left: 60px;
//    }
//  }
//
//  @include media(1280px){
//
//    .slide-item{
//      padding: vw(60px) 60px vw(30px);
//
//      .title{
//        font-size: 64px;
//        line-height: 130%;
//      }
//      .special-desc{
//        padding-bottom: 100px;
//      }
//      .desc-text{
//        p{
//          font-size: 16px;
//          line-height: 150%;
//        }
//      }
//    }
//    .circle_button{
//      right: 6%;
//    }
//    .slide-bottom-right{
//      font-size: 14px;
//    }
//    .customize-tools{
//      bottom: vw(30px);
//      left: 60px;
//    }
//  }
//  @include media(1024px){
//
//    .slide-item{
//      padding: 40px 30px 25px;
//    }
//    .customize-tools{
//      left: 30px;
//    }
//  }
//  @include media(768px){
//
//    .tns-outer{
//      margin-left: 0;
//      width: 100%;
//    }
//    .slide-item{
//      padding: 40px 15px 25px;
//
//      .title{
//        font-size: 32px;
//        line-height: 130%;
//      }
//      .special-desc{
//        padding-bottom: 180px;
//      }
//      .desc-text{
//        p{
//          line-height: 180%;
//        }
//      }
//    }
//    .circle_button{
//      top: 50%;
//      right: 10%;
//    }
//
//    .slide-bottom-right{
//      font-size: 12px;
//      line-height: 26px;
//
//      i{
//        width: 50px;
//        margin-left: 10px;
//        margin-right: 10px;
//      }
//    }
//    .customize-tools{
//      bottom: 25px;
//      left: 15px;
//    }
//
//  }
//
//  @media (max-height: 500px) and (min-width: 1440px){
//    .slider-wrapper{
//      height: auto!important;
//    }
//    .slide-item{
//      height: auto;
//    }
//    .special-desc{
//      margin-bottom: 10vh;
//    }
//  }
//}



.main-title.h1.utm > h1 {
  padding-right: 0;
    font-size: 3.54167vw;
    font-weight: 800;
    line-height: 130%;
}